<template>
	<div id="getWidth">
		<template v-if="!loading">
			<address-form-type-2
				v-if="addressTypes.address_form_type_2.includes(countryCode)"
				:user-country="userCountry"
				:form-import="form"
				:display-override-toggle="displayOverrideToggle"
				:errors-import="errorsImport"
				:allow-change-country="enableChangeCountry"
				:show-country-field="enableChangeCountry"
				:loading="loading"
				:same-as-billing="sameAsBilling"
				:prefix-errors="prefixErrors"
				:address="address"
				:countries="countryData"
				:country-code-import="countryCodeImport"
				:display-cancel-button="displayCancelButton"
				:display-submit-button="displaySubmitButton"
				:display-goback-button="displayGobackButton"
				:hide-name-fields="hideNameFields"
				@on-submit="addressInfoSubmit"
				@requestData="requestData" />
			<address-form-type-3
				v-else-if="addressTypes.address_form_type_3.includes(countryCode)"
				:user-country="userCountry"
				:countries="countryData"
				:states="stateData"
				:form-import="form"
				:display-override-toggle="displayOverrideToggle"
				:errors-import="errorsImport"
				:loading="loading"
				:same-as-billing="sameAsBilling"
				:prefix-errors="prefixErrors"
				:address="address"
				:country-code-import="countryCodeImport"
				:display-cancel-button="displayCancelButton"
				:display-submit-button="displaySubmitButton"
				:display-goback-button="displayGobackButton"
				:allow-change-country="enableChangeCountry"
				:hide-country-field="hideCountryField"
				:hide-name-fields="hideNameFields"
				:validate-personal-document="validatePersonalDocument"
				:current-personal-document-front-file-import="currentPersonalDocumentFrontFile"
				:current-personal-document-back-file-import="currentPersonalDocumentBackFile"
				:guest-checkout="guestCheckout"
				:show-address-agreement="showAddressAgreement"
				@submitDisabled="$emit('submitDisabled', $event)"
				@country-change="onCountryChange"
				@on-submit="addressInfoSubmit"
				@requestData="requestData" />
			<address-form
				v-else
				:user-country="userCountry"
				:countries="countryData"
				:states="stateData"
				:form-import="form"
				:display-override-toggle="displayOverrideToggle"
				:errors-import="errorsImport"
				:loading="loading"
				:same-as-billing="sameAsBilling"
				:prefix-errors="prefixErrors"
				:address="address"
				:country-code-import="countryCodeImport"
				:display-cancel-button="displayCancelButton"
				:display-submit-button="displaySubmitButton"
				:display-goback-button="displayGobackButton"
				:allow-change-country="enableChangeCountry"
				:hide-country-field="hideCountryField"
				:hide-name-fields="hideNameFields"
				:validate-personal-document="validatePersonalDocument"
				:current-personal-document-front-file-import="currentPersonalDocumentFrontFile"
				:current-personal-document-back-file-import="currentPersonalDocumentBackFile"
				@country-change="onCountryChange"
				@on-submit="addressInfoSubmit"
				@requestData="requestData" />
		</template>
		<is-loading
			v-else
			:loading-label="translate('loading')"
			:loading="loading" />
	</div>
</template>
<script>
import { ADDRESSES, VALIDATE_PERSONAL_DOCUMENT_COUNTRIES } from '@/settings/AddressBook';
import { COUNTRY_ADDRESS_FORM_TYPES, ALLOW_CHANGE_BILLING_COUNTRY } from '@/settings/AddressForm';
import AddressFormType2 from '@/components/AddressFormType2';
import AddressFormType3 from '@/components/AddressFormType3';
import AddressForm from '@/components/AddressForm';
import { Profile, Countries, Addresses } from '@/translations';
import ElementResolution from '@/mixins/ElementResolution';
import WindowSizes from '@/mixins/WindowSizes';
import Country from '@/util/Country';

export default {
	name: 'AddressCountryForm',
	messages: [Profile, Countries, Addresses],
	components: { AddressForm, AddressFormType2, AddressFormType3 },
	mixins: [ElementResolution, WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		sameAsBilling: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		prefixErrors: {
			type: String,
			default: '',
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		address: {
			type: String,
			default: ADDRESSES.default,
		},
		countryCodeImport: {
			type: String,
			default: '',
		},
		marketCountryCode: {
			type: String,
			default: '',
		},
		userCountry: {
			type: Boolean,
			default: false,
		},
		displayCancelButton: {
			type: Boolean,
			default: false,
		},
		displaySubmitButton: {
			type: Boolean,
			default: true,
		},
		displayOverrideToggle: {
			type: Boolean,
			default: false,
		},
		displayGobackButton: {
			type: Boolean,
			default: true,
		},
		allowChangeCountry: {
			type: Boolean,
			default: false,
		},
		hideCountryField: {
			type: Boolean,
			default: false,
		},
		hideNameFields: {
			type: Boolean,
			default: false,
		},
		currentPersonalDocumentFrontFile: {
			type: String,
			default: '',
		},
		currentPersonalDocumentBackFile: {
			type: String,
			default: '',
		},
		guestCheckout: {
			type: Boolean,
			default: false,
		},
		showAddressAgreement: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			countryCode: this.countryCodeImport,
			selectedCountryIso: '',
			addressTypes: COUNTRY_ADDRESS_FORM_TYPES,
			allowChangeBillingCountry: ALLOW_CHANGE_BILLING_COUNTRY,
			country: new Country(),
			states: new Country(),
		};
	},
	computed: {
		countryData() {
			try {
				const { data } = this.country.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		stateData() {
			try {
				const { data } = this.states.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		validatePersonalDocument() {
			return VALIDATE_PERSONAL_DOCUMENT_COUNTRIES.includes(this.countryCode) && this.address === ADDRESSES.shipping && this.$route.name !== 'CustomCartsGenerate';
		},
		enableChangeCountry() {
			const isBilling = this.address === ADDRESSES.billing;
			const allowChangeBilling = this.allowChangeBillingCountry.includes(this.marketCountryCode);

			return this.allowChangeCountry || (isBilling && allowChangeBilling);
		},
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
		'form.country_code': function countryCodeWatcher(value) {
			if (value !== '' && value !== undefined && typeof this.form !== 'undefined') {
				this.form.region_id = this.formImport.region_id || '';
				this.onCountryChange(value);
			}
		},
		formImport: {
			handler(value) {
				this.form = value;
			},
			deep: true,
		},
		countryCodeImport(value) {
			if (value !== '') {
				this.countryCode = value;
			} else {
				this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
			}
			this.form.country_code = this.countryCode;
		},
	},
	mounted() {
		if (this.address === ADDRESSES.billing) {
			this.country.getBillingCountries({ from_market: this.marketCountryCode }).then(() => {
				// eslint-disable-next-line camelcase
				this.form.country_code = this.countryCodeImport || (this.$user.details().country?.iso_code_2 || '');
				this.form.region_id = this.formImport.region_id || '';
			});
		} else {
			this.country.getCountries().then(() => {
				// eslint-disable-next-line camelcase
				this.form.country_code = this.countryCodeImport || (this.$user.details().country?.iso_code_2 || '');
				this.form.region_id = this.formImport.region_id || '';
			});
		}

		if (!this.countryCode) {
			this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
		}
		this.onCountryChange(this.countryCode);
	},
	methods: {
		addressInfoSubmit() {
			this.$emit('on-submit', this.form);
		},
		requestData() {
			this.$emit('update:formImport', this.form);
		},
		hasError(field) {
			if (typeof this.errors[`${this.prefixErrors}${field}`] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		onCountryChange(countryCode) {
			this.countryCode = countryCode;
			this.form.region_id = null;
			this.form.address = '';
			this.form.address2 = '';
			this.form.address3 = '';
			this.form.address4 = '';
			this.form.city = '';
			this.form.postcode = '';
			this.form.country_code = countryCode;
			this.form.region_name = '';
			this.form.region_code = '';
			this.form.state_code = '';

			if (countryCode !== '' && countryCode !== undefined) {
				this.states.getStates(countryCode).then((response) => {
					const [state] = response;
					if (typeof state === 'object') {
						this.form.region_id = this.formImport.region_id || '';
					}
				});
			}
		},
	},
};
</script>
